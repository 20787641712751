$(function(){
    $('.accordion-list').each(function(){
		$(this).on("click", ".accordion-list__item .accordion-item__header", function () {
			var par = $(this).closest('.accordion-list__item');
			if(par.hasClass('open')){
				par.removeClass('open');
				par.find('.accordion-item__content').slideUp();
			} else{
				par.addClass('open');
				par.find('.accordion-item__content').slideDown();
				par.siblings().removeClass('open').find('.accordion-item__content').slideUp();
			}
		});
	});
});
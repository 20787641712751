$(function(){

    var locations = [];
    var maps = [];
    var feed = $('section.venue-list').attr('data-url');

    //$.getJSON("data/venue_data.json", function(data){ //localhost file
    //$.getJSON("/umbraco/surface/json/GetVenueFeed/?pageId=7459", function(data){  //live site file
    $.getJSON(feed, function(data){
        $.each(data, function(i){
            locations.push(data[i]);
            console.log(locations);
        });

        for (var i = 0; i < locations.length; i++) {
            //$('.venue-card__map').append('<div id="map' + i + '" style="height:50vh;"></div>');
            console.log(locations[i].venue.lat);
            $('.venue-list__container').append(`
                <div class="venue-card">
                    <div class="venue-card__info">
                        <h2>` + locations[i].venue.title + `</h2>
                        <p>` + locations[i].venue.text + `</p>

                        <address>
                            ` + locations[i].venue.address + `
                        </address>

                        <a href="tel:` + locations[i].venue.telephone + `">` + locations[i].venue.telephone + `</a>
                    </div>
                    <div class="venue-card__map">
                        <div id="map` + i + `"></div>
                    </div>
                </div>
            `);
    
            var latlng = {lat: locations[i].venue.lat, lng: locations[i].venue.lng};
    
            var mapOptions = {
                center: latlng,
                scrollwheel: false,
                zoom: 15,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            };
    
            var map = new google.maps.Map(document.getElementById('map' + i), mapOptions);
            maps.push(map);
    
    
            var marker = new google.maps.Marker({
                map: map,
                position: latlng,
                title: locations[i].title
            });
        };

    });
    
});
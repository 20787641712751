// var $menu,
// 	hBtns;

// function menu_open(el){
// 	var target = el.attr('class').split('-')[1],
// 		$target = $menu.find('#menu-gbl-'+target),
// 		pages = $menu.find('.menu-page');
// 	el.addClass('active').siblings('button[class*=toggle-]').removeClass('active');
// 	$menu.fadeIn(300);
// 	pages.hide();
// 	$target.fadeIn(300);
// 	// SET BODY CONDITIONS
// 	$('#window').css({
// 		'height' : $menu.outerHeight(),
// 		'overflow':'hidden'
// 	});
// 	body.addClass('takeover-open');
// }

// function menu_close(){
// 	hBtns.removeClass('active');
// 	$menu.fadeOut(300);
// 	body.removeClass('takeover-open');
// 	$('#window').attr('style','');
// }

// $(function(){
// 	$menu = $('#menu-gbl')
// 	hBtns = $('#header-gbl').find('button[class*=toggle-]');
// 	hBtns.click(function(){
// 		if($(this).hasClass('active')){
// 			menu_close();
// 		} else{
// 			menu_open($(this));
// 		}
// 	});
// });

$(function(){

  document.getElementById('menu-gbl').addEventListener("click", function () {
    $('#menu-gbl').removeClass('open');
    $('#menu-gbl-menu').removeClass('menu-open');
    $('#menu-gbl-search').removeClass('search-open');
    $('body').removeClass('takeover-open');
  }, false);
  document.querySelector('#menu-gbl-menu').addEventListener("click", function (ev) {
    ev.stopPropagation();
  }, false);
  document.querySelector('#menu-gbl-search').addEventListener("click", function (ev) {
    ev.stopPropagation();
  }, false);

  // $('#menu-gbl').on("click", function () {
  //   $('#menu-gbl').removeClass('open');
  //   $('#menu-gbl-menu').removeClass('menu-open');
  //   $('#menu-gbl-search').removeClass('search-open');
  //   $('body').removeClass('takeover-open');
  // });

  $('.toggle-menu').on("click", function () {
      $('#menu-gbl').toggleClass('open');
      $('#menu-gbl-menu').toggleClass('menu-open');
      $('#menu-gbl-search').removeClass('search-open');
      $('body').toggleClass('takeover-open');

      // if($(this).hasClass('open')){
      //     $('#menu-gbl').removeClass('open');
      //     $('#menu-gbl-menu').removeClass('menu-open');
      //     $('body').removeClass('takeover-open');
      // } else{
      //     $($(this)).addClass('open');
      //     $('#menu-gbl-menu').addClass('menu-open');
      //     $('#menu-gbl-search').removeClass('search-open');
      //     $('body').addClass('takeover-open');

      // }
  });
  $('.toggle-search').on("click", function () {
      $('#menu-gbl').toggleClass('open');
      $('#menu-gbl-search').toggleClass('search-open');
      $('#menu-gbl-menu').removeClass('menu-open');
      $('body').toggleClass('takeover-open');
  });

  $('ul.l0 li.has-sub').click(function(){
      if($(this).hasClass('active')){
        $(this).removeClass('active');
      }else{
        $(this).addClass("active").siblings().removeClass("active"); 
      }
  });






    //tabs
  function tabUpdate(dest){
		var target = $('#'+dest);
		if(target.length > -1){
			target.fadeIn().siblings('.view').hide();
		}
	}
	$('.view-button-container').each(function(){
		var tab = $(this).find('.view-button');
		tab.click(function(){
			$(this).addClass('active').siblings().removeClass('active');
			tabUpdate($(this).attr('data-target'))
		}).first().click();
	});
});
$(function(){
  const heroswiper = new Swiper('.hero-swiper', {
      // Optional parameters
      loop: true,
      autoplay: {
          delay: 5000,
      },
      speed: 1000
  });

  const categoryswiper = new Swiper('.category-swiper', {
      // Optional parameters
      loop: false,
      slidesPerView: 3,
      breakpoints: {
          480: {
            slidesPerView: 6
          },
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
  });

  const navcategoryswiper = new Swiper('.category-swiper.nav-category-swiper', {
    // Optional parameters
    loop: false,
    slidesPerView: 3,
    breakpoints: {
        480: {
          slidesPerView: 4
        },
      }
  });

  const galleryswiper = new Swiper('.gallery .gallery-swiper', {
      // Optional parameters
      loop: false,
      slidesPerView: 1,
      spaceBetween: 10,
      breakpoints: {
        480: {
          slidesPerView: 3
        },
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
  });

  const eventgalleryswiper = new Swiper('#event-gallery .gallery-swiper', {
    // Optional parameters
    loop: false,
    autoplay: {
        delay: 5000,
    },
    speed: 1000,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
    spaceBetween: 10,
    watchOverflow: true
  });
})
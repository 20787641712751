var lightBox = function(content,callback){
	var lightbox = $('<div>',{'id':'lightBox'});
	var	frame = $('<div>',{'class':'frame'});
	var close = $('<div>',{'class':'close'});	
    frame.append(close);
	lightbox.append(frame);
    frame.append(content);
    lightbox.hide().appendTo('body');
    lightbox.fadeIn();
    if(typeof callback == 'function'){
    	callback()
    }
    if(content.outerHeight() > lightbox.height()){
        content.addClass('overflow')
    }
    $('body').addClass('vidbox-open');
    $('body').trigger('lightbox-open');

    //close lightbox on pressing esc key
    $(document).keyup(function(e){
        if (e.keyCode === 27){
            close.click();
        }
    });
    
    close.click(function(){
    	lightbox.fadeOut();
    	$('body').removeClass('vidbox-open');
    	setTimeout(function(){
    		lightbox.empty().remove();
    	},300);
    });
}

var inlinePlayer = [];
var bgPlayer = [];


function labnolThumb(id) {
    var thumb = '<img src="https://i.ytimg.com/vi/ID/maxresdefault.jpg">',
        play = '<div class="play"></div>';
    return thumb.replace("ID", id) + play;
}
function ytEmbed(id,controls) {
    var iframe = document.createElement("iframe");
    var embed = "https://www.youtube.com/embed/ID?autoplay=1";
    if(!controls){
    	embed = embed + "&controls=0&showinfo=0&autohide=2&disablekb=1&loop=1&modestbranding=1&rel=0";
    }
    iframe.setAttribute("src", embed.replace("ID", id));
    iframe.setAttribute("frameborder", "0");
    iframe.setAttribute("allowfullscreen", "1");
    return iframe
}

var videoBox = function(vid){
	var player = document.createElement("div");
	var	frame = $('<div>',{'class':'vid-frame'});
    player = ytEmbed(vid,1);
	frame.append(player);
    lightBox(frame,function(){
    	frame.fitVids();
    });
}

var internalVideoBox = function(vid){
	var player = document.createElement('source');
	player.setAttribute('src', vid);
	player.setAttribute('type', 'video/mp4');
	var	video = document.createElement('video');
	video.setAttribute("controls", '');
	video.setAttribute("autoplay", '');
	video.append(player);
	var	frame = $('<div>',{'class':'vid-frame'});
	frame.append(video);
    lightBox(frame,function(){
    	frame.fitVids();
    });
}

// -------------------------
// POPUP VIDS
// -------------------------
$(function(){
	$('.video-popup-button.video-popup').on('click', function() {
		internalVideoBox($(this).attr('data-video'));	
	});
})


window.onYouTubeIframeAPIReady = function() {
	// -------------------------
	// YT POPUP VIDS
	// -------------------------
	var videoPops = $('.video-popup-button.youtube-popup');
	videoPops.click(function(){
		videoBox($(this).attr('data-video'));
	});

	// -------------------------
	// INLINE VIDEOS
	// -------------------------
	var videoInline = $('.video-inline');
	videoInline.each(function(i){
		var vid = $(this),
			vidID = 'player_' + i,
			vidSrc = vid.attr('data-video'),
			frame = $('<div>',{'id':vidID}),
			player;
		vid.click(function(){
			if(!vid.hasClass('active')){

				vid.append(frame)
				frame.fitVids();
				inlinePlayer[i] = new YT.Player(vidID, {
						height: '390',
						width: '640',
						videoId: vidSrc,
						playerVars: {
		            		'rel': 0,
			                'modestbranding': 1,
							'disablekb': 1,
							'controls': 1,
							'playlist': vidSrc,
		            		'fs':0,
		            		'autoplay': 1,
		            		'autohide': 1,
		            		'color': 'white',
		            		'ecver':2
			            },
						events: {
							'onReady': onPlayerReady,
		            		'onPlaybackQualityChange': onPlayerPlaybackQualityChange,
							'onStateChange': onPlayerStateChange
						}
					});
				player = inlinePlayer[i];
				function onPlayerReady(event) {
					player.playVideo();
					player.setVolume(0);
					vid.addClass('active');
				}
				var done = false;
				function onPlayerStateChange(data) {
					if(data.data === 1){
						frame.fitVids();			
						vid.removeClass('paused')
					} else if (data.data === 2){
						// PAUSED
						vid.addClass('paused');
					}
				}
				function onPlayerPlaybackQualityChange(){
					// 
				}
			} else if(vid.hasClass('paused')){
				player.playVideo();
			} else{
				player.pauseVideo();
			}
		})
	});
}

$(function(){

    if($('video').length){
        video = document.getElementById("video");
    
        //autoplay in edge
        setTimeout(function(){
            video.play();
        }, 3000);
    
        $(window).resize(function(){
            //if video container AR is less than 16/9 AR, then set height to make the height 100% of the video container and then overflow width
            if($('.background-video').width() / $('.background-video').height() < 16/9){
                $('.background-video').find('.video-wrapper').css('width', (16 * $('.background-video').height() / 9));
            }else{
                $('.background-video').find('.video-wrapper').css('width', "100%");
            }
        }).resize();
    }

});
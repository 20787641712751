$(function(){
    $('.cta.read-more').on('click', function(){
        $('.expanding-text').toggleClass('-expanded');
        
        if ($('.expanding-text').hasClass('-expanded')) {
          $('.cta.read-more').html('Read Less');
        } else {
          $('.cta.read-more').html('Read More');
        }
    });
});
document.addEventListener("DOMContentLoaded", function() { 

    const optionMenu = document.querySelector(".select-container"),
    selectBtn = optionMenu.querySelector(".select-btn"),
    options = optionMenu.querySelectorAll(".option"),
    sBtn_text = optionMenu.querySelector(".select-text");

    selectBtn.addEventListener("click", () =>
        optionMenu.classList.toggle("active")
    );

    options.forEach((option) => {
        option.addEventListener("click", () => {
            // let selectedOption = option.innerText;
            // sBtn_text.innerText = selectedOption;
            option.classList.toggle("active");
        
            //optionMenu.classList.remove("active");
        });
    });

});
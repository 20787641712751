let eventFeed = {};

document.addEventListener("DOMContentLoaded", function() { 
  let cal = document.getElementById('calendar');
  let jsonURL = cal.getAttribute('data-events');
  
  var xhReq = new XMLHttpRequest();
  xhReq.open("GET", jsonURL, false);
  xhReq.send(null);
  eventFeed = JSON.parse(xhReq.responseText);

  var currentDate = new Date().toISOString().slice(0, 10);

  var options = {
      date: {
          min: "1920-01-01",
          max: "2038-12-31"
      },
      settings: {
          range: {
              min: currentDate
          },
          visibility: {
              disabled: true,
              theme: 'light'
          }
      },
      actions: {
        getDays(day, date, HTMLElement, HTMLButtonElement) {
            HTMLButtonElement.style.flexDirection = 'column';
            HTMLButtonElement.innerHTML =
            `
                <span>${day}</span>
                <!--<span style="font-size: 8px;color: #8BC34A;">some txt</span>-->
            `;
        },
        clickDay(event, self) {
            console.log("Selected Date:", self.selectedDates[0]);
            popEventData(self.selectedDates[0]);
            setTimeout(assignEventsToCalendar, 100);
            document.querySelector('.no-event').classList.add('hidden');
        },
        clickArrow: assignEventsToCalendar
      }
  };

  var calendar = new VanillaCalendar("#calendar", options);
  calendar.init();

  setTimeout(assignEventsToCalendar, 100);

  //assignEventsToCalendar();

});

const assignEventsToCalendar = function(){
    let eventData = eventFeed;
   
    for (const {
        days
        } of eventData) {
        for (const {
            day,
            events
        } of days) {
            const key = day.split('-').map(p => p.padStart(2, '0')).join('-');
            let dayElement = document.querySelector(`[data-calendar-day="${key}"]`);
            
            if (dayElement) {
                let eventWrapper = document.createElement("div");
                eventWrapper.classList.add("event-wrapper");
                dayElement.append(eventWrapper);
                dayElement.classList.add('events')
                for (const {
                    title,
                    category
                    } of events) {
                    let singleEvent = document.createElement("div");
                    singleEvent.classList.add("event", category[0]);
                    eventWrapper.append(singleEvent);
                }
            }
        }
    }
};

popEventData = function(dateData) {
    const eventListContainer = document.querySelector('.event-list');
    //Clear container before populating
        eventListContainer.innerHTML = '';
      if (dateData) {
        console.log('render date info');
    
        let selectedDate = new Date(dateData);
        let selectedYear = selectedDate.getFullYear();
        let selectedMonth = selectedDate.getMonth() + 1; //Plus one because Jan is 0 
        let selectedDay = selectedDate.getDate();
        let eventData = eventFeed;
    
    
            //Loop through dates
        for (const {
            month,
            days
          } of eventData) {
          //If selected month exists in JSON continue
          if (month === `${selectedYear}-${selectedMonth}`) {
    
            //Loop through all events this month
            for (const {
                day,
                events
              } of days) {
    
              //If current day matches the clicked day on the calendar
              if (day == `${selectedYear}-${selectedMonth}-${selectedDay}`) {
              
                  //Render the events and details on the page
                renderEvents(events, dateData);
                console.log('open dropdown');
              }
    
            }
    
          }
        }
        
      }
    
    };

    //render events
    const renderEvents = (events, date) => {

        const eventListContainer = document.querySelector('.event-list');
        //Clear container before populating
            eventListContainer.innerHTML = '';
            
        //Simplify the date
        const dateOptions = {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
        };

        for(i=0;i<events.length;i++){
        
        
        let eventCat = events[i].category[0];
        let eventTitle = events[i].title;
        let eventBoringDate = new Date(date);
        let eventDate = events[i].timing //eventBoringDate.toLocaleDateString('en-GB', dateOptions);
        let eventLink = events[i].url;
        let eventDesc = events[i].text;
        let eventTime = '8pm';
        let eventImg = events[i].imageUrl;
        
            
            eventListContainer.innerHTML += `
        <div class="event-item" data-category="${eventCat.toLowerCase()}">
            <a href="${eventLink}">
                <div class="event-item__image">
                    <figure>
                        <img src="${eventImg}" alt=""> 
                    </figure>
                </div>
                <div class="event-item__desc">
                    <span class="event-desc__category">${eventCat}</span>
                    <h3 class="event-desc__title">${eventTitle}</h3>
                    <span class="event-desc__date">${eventDate}</span>
                    <span class="event-desc__info">${eventDesc}</span>
                </div>
                <span class="cta">Book Tickets</span>
            </a>
            </div>
        `;
    
        }
            
    }